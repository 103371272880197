import { Controller } from '@hotwired/stimulus';
import intlTelInput from 'intl-tel-input/build/js/intlTelInputWithUtils';
import { geoIpLookup } from '../utils/phoneControllerUtils';

/**
 * Extends the Controller class to enhance an input field for international telephone numbers.
 * This class automatically configures the input field to use the intlTelInput plugin for formatting
 * and validating telephone numbers. It also attempts to set the initial country selection based on
 * the user's IP address using the ipapi service.
 */
export default class extends Controller {
  // Defines the DOM element targets that this controller will handle.
  static targets = ['name'];

  /**
   * Called when the controller is connected to the DOM. It initializes the intlTelInput plugin
   * on the target input field and sets up event listeners for input events to format and validate
   * the telephone number. It also attempts to set the initial country selection based on the user's
   * IP address.
   */
  connect() {
    const input = this.nameTarget;
    let ini = this.initIntlPlugin(input);

    ['input', 'change'].forEach(key =>
      input.addEventListener(key, () => {
        this.convertIntlFormat(ini, input);
      }),
    );
  }

  /**
   * Initializes the intlTelInput plugin with specific configurations for the input field.
   * @param {HTMLElement} input - The input element to be enhanced with the intlTelInput plugin.
   * @returns The initialized intlTelInput instance.
   */
  initIntlPlugin(input) {
    return intlTelInput(input, {
      autoInsertDialCode: true,
      initialCountry: 'auto',
      geoIpLookup: geoIpLookup,
    });
  }

  /**
   * Converts the input field's value to an international format if it's a valid number.
   * It also updates the input's class to reflect the validation state.
   * @param {Object} ini - The intlTelInput instance for the input field.
   * @param {HTMLElement} input - The input element to format and validate.
   */
  convertIntlFormat(ini, input) {
    if (ini.isValidNumberPrecise()) {
      let number = ini.getNumber();
      input.classList.remove('is-invalid');
      input.classList.add('is-valid', 'was-validated');
      input.value = number;
      input.dataset.valid = true;
    } else {
      input.classList.remove('is-valid', 'was-validated');
      input.classList.add('is-invalid');
      input.dataset.valid = false;
    }
  }
}
